.section {
  height: 100vh;
  padding: 1.5rem;
  margin-left: 50px;
  border-radius: 20px;
  justify-content: center;
  border: transparent;
  background-color: transparent;
}

.home {
  background: linear-gradient(to bottom, #001a35, rgba(0, 255, 204, 0.582));
  color: white;
  justify-content: center;
  background-size: cover;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0, 0, 0);
}

.websitelinks {
  margin-top: 20px;
  background-color: blanchedalmond;
  width: 50%;
  padding: 10px;
  align-items: center;
  margin: 5%;
}
